<template>
  <div>
    <aad-loader v-if="!survey || !survey.id || !survey.user"></aad-loader>
    <aad-loader v-else-if="surveySubmitting" message="Submitting CME Claim, Please Wait"></aad-loader>
    <div v-else>
      <div v-if="session">
        <aad-title>{{ session.SessionCode }} - {{ session.Name }}</aad-title>
      </div>
      <div v-else>
        <aad-title>Overall Feedback</aad-title>
      </div>
      <div>
        <div
          v-if="
            survey &&
            survey.user &&
            survey.session &&
            survey.session.maxCredits != undefined
          "
          class="my-3"
        >
          <p>Select the number of credits you would like to claim:</p>
          <div class="alert alert-danger" role="alert" v-if="creditOverage">
            You have claimed too many credits for this day. This option will
            leave you over the daily limit by {{ creditOverage }}. Please edit
            other claims, or lower the amount of credits you wish to recieve for
            this session.
          </div>

          <select v-model="survey.user.creditsClaimed" class="form-control">
            <option
              v-for="index in survey.session.maxCredits * 4"
              :key="index"
              :value="0.25 * index"
            >
              {{ (0.25 * index).toFixed(2) }}
            </option>
          </select>
        </div>

        <div
          v-for="questionGroup in survey.questionGroups"
          :key="questionGroup.label"
          class="m-2"
        >
          <div v-html="convertMarkdown(questionGroup.label)"></div>
          <div>
            <div
              v-for="(question, index) in questionGroup.questions"
              :key="question.id + '-' + index"
              class="m-2"
            >
              <CME-question :question="question"> </CME-question>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          class="alert alert-danger my-2"
          role="alert"
          v-if="creditOverage > 0"
        >
          Fix errors above before you are able to submit.
        </div>
        <button
          class="btn btn-primary btn-primary-solid"
          href="#"
          @click="submitCME"
          :disabled="creditOverage > 0"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CMEQuestion from "../components/CMEQuestion.vue";
import showdown from "showdown"; // Shawdown is a markdown converter.
import AadLoader from "../components/AadLoader.vue";

export default {
  data() {
    return {
      sessionId: this.$route.params.id,
      isEdit: false,
      survey: {
        User: {
          CreditsClaimed: 0,
        },
        Session: {
          MaxCredits: 0,
        },
      },
      surveySubmitting: false,
    };
  },
  metaInfo() {
    return {
      title: this.session ? this.session.Name : "Session Details",
    };
  },
  mounted() {
    this.getSessions();
    this.getSpeakers();
    this.getCMEClaims();

    if (this.sessionId.toUpperCase() == "OVERALL") {
      this.getCMESurveyFromServer("OVERALL");
    }
    if (this.session && this.session.SessionCode) {
      this.getCMESurveyFromServer(this.session.SessionCode);
    }
  },
  methods: {
    getSessions() {
      this.$store.dispatch("loadSessions");
    },
    getSpeakers() {
      this.$store.dispatch("loadSpeakers");
    },
    getCMEClaims() {
      this.$store.dispatch("loadAttendeeCMEClaims");
    },
    getCMESurveyFromServer(sessionCode) {
      fetch(
        `https://meetings.aad.org/api/eval/Session/${sessionCode}?meetingCode=${this.getMeetingCode}&regCode=${this.loggedInUser.registrationCode}`
      )
        .then((resp) => resp.json())
        .then((data) => {
          this.survey = data;

          // Save previously claimed credits for comparisson when editing
          if (
            this.survey &&
            this.survey.user &&
            this.survey.user.creditsClaimed
          ) {
            this.survey.previouslyClaimedCredits =
              this.survey.user.creditsClaimed;
          } else {
            // This is a new claim
            // Set previously claimed credits to 0 for historical purposes and checking
            this.survey.previouslyClaimedCredits = 0;

            if (this.survey.session)
              // Set current claim to max value per request of Joseph and Edu.
              this.survey.user.creditsClaimed = this.survey.session.maxCredits;
          }

          // Loop through questions and format them for vue
          this.survey.questionGroups.forEach((qg) => {
            if (qg.questions != null) {
              qg.questions.forEach((question) => {
                if (
                  question.type != "Checkbox" &&
                  question.userResponse != null &&
                  question.userResponse.length == 1 &&
                  !question.userResponse[0].startsWith("Other")
                ) {
                  // If there is just one answer, unpack from an array
                  question.userResponse = question.userResponse[0];
                } else if (
                  question.allowOther &&
                  question.type != "Checkbox" &&
                  question.userResponse != null &&
                  question.userResponse.length == 1 &&
                  question.userResponse[0].startsWith("Other")
                ) {
                  // Set the question response to other and then save the other
                  question.otherResponse = question.userResponse[0].replace(
                    "Other - ",
                    ""
                  );
                  question.userResponse = "Other";
                } else if (
                  question.allowOther &&
                  question.type == "Checkbox" &&
                  question.userResponse != null &&
                  question.userResponse.length >= 1
                ) {
                  // Check for others on checkboxes.
                  question.userResponse.forEach((response, index) => {
                    if (response.startsWith("Other -")) {
                      // Add the other response
                      question.userResponse.push("Other");
                      // Add their answer to otherAnswer
                      question.otherResponse = response.replace("Other - ", "");
                      // Remove the current un-processed other
                      question.userResponse.splice(index, 1);
                    }
                  });
                }
              });
            }
          });
        });
    },
    convertMarkdown: function (item) {
      var converter = new showdown.Converter(),
        html = converter.makeHtml(item);

      return html;
    },
    getServerCMEPayload() {
      let payload = {};

      // Set the session code
      if (this.sessionId.toUpperCase() == "OVERALL") {
        payload.SessionCode = "OVERALL";
      }
      if (this.session && this.session.SessionCode) {
        payload.SessionCode = this.session.SessionCode;
      }
      // Set user's reg code
      if (this.loggedInUser) {
        payload.RegCode = this.loggedInUser.registrationCode;
      }

      // Set claim date
      payload.CreditClaimDate = new Date();
      payload.QuestionResponses = questionResponses;
      payload.MeetingCode = this.getMeetingCode;
      payload.CreditsClaimed = this.survey.user.creditsClaimed;
      payload.SurveyContentfulId = this.survey.id;

      // Set question responses
      let questionResponses = [];

      // Loop through each group's questions and add to responses
      this.survey.questionGroups.forEach((group) => {
        // If there aren't questions in the group, skip it.
        if (group.questions == null) {
          return;
        }

        // Loop through questions in a group.
        group.questions.forEach((question) => {
          // Copy with JSON for value and not reference so we don't change data
          let currentUserResponse = JSON.parse(
            JSON.stringify(question.userResponse)
          );

          // If an array check for Other values and replace
          if (Array.isArray(currentUserResponse)) {
            if (currentUserResponse.includes("Other")) {
              currentUserResponse = currentUserResponse.filter(
                (resp) => resp !== "Other"
              );
              currentUserResponse.push("Other - " + question.otherResponse);
            }
          }

          // Check for other and replace value
          if (currentUserResponse == "Other") {
            currentUserResponse = "Other - " + question.otherResponse;
          }

          // Add the responses to the questionResponses object.
          questionResponses.push({
            QuestionId: question.id,
            Value: Array.isArray(currentUserResponse)
              ? null
              : currentUserResponse,
            Values: Array.isArray(currentUserResponse)
              ? currentUserResponse
              : null,
          });
        });
      });
      payload.QuestionResponses = questionResponses;

      console.log("Payload", payload);
      return payload;
    },
    submitCME() {
      this.surveySubmitting = true;

      let serverPayload = this.getServerCMEPayload();

      // Submit to server
      fetch(`https://meetings.aad.org/api/eval/Session/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(serverPayload),
      }).then((response) => {
        if (response.status == 200) {
          // If successful, commit to firestore
          // Dispatch method to save to firestore
          this.$store
            .dispatch("saveAttendeeCreditClaim", serverPayload)
            .then(() => {
              // After saving to the store
              this.$router.push("/CME");
            });
        } else {
          this.surveySubmitting = false;
          console.log(" ERROR ERROR ERROR ", response);
        }
      });
    },
  },
  components: {
    CMEQuestion,
    AadLoader,
  },
  computed: {
    ...mapGetters([
      "allSessions",
      "getSessionById",
      "getSpeakerById",
      "allSpeakers",
      "isLoggedIn",
      "getMeetingCode",
      "loggedInUser",
      "checkNumberOfCreditsAvailableOnDay",
    ]),
    session() {
      return this.getSessionById(this.sessionId);
    },
    creditsAvailableOnDay() {
      return this.checkNumberOfCreditsAvailableOnDay(this.session);
    },
    directors: function () {
      return this.session.Speakers
        ? this.session.Speakers.filter((speaker) => {
            return speaker.Role == "Director";
          })
        : null;
    },
    codirectors: function () {
      return this.session.Speakers
        ? this.session.Speakers.filter((speaker) => {
            return speaker.Role == "Co-Director";
          })
        : null;
    },
    speakers: function () {
      return this.session.Speakers
        ? this.session.Speakers.filter((speaker) => {
            return speaker.Role == "Speaker";
          })
        : null;
    },
    // Retrieve the full records as we will need it for disclosures.
    allSessionFaculty: function () {
      let output = [];
      for (let i = 0; i < this.session.Speakers.length; i++) {
        let match = this.allSpeakers.find((speaker) => {
          return speaker.id == this.session.Speakers[i].SpeakerId;
        });
        if (match) output.push(match);
      }
      return output;
    },
    creditsLeftAfterClaim: function () {
      // Return 0 for overall to prevent errors
      if (this.survey && this.survey.sessionCode == "OVERALL") {
        return 0;
      }

      // Use the previously stored credit value if present to calculate how many credits can be claimed.
      if (!this.survey || !this.survey.user) {
        return -1;
      }
      return (
        this.creditsAvailableOnDay +
        this.survey.previouslyClaimedCredits -
        this.survey.user.creditsClaimed
      );
    },
    creditOverage: function () {
      return this.creditsLeftAfterClaim >= 0 ||
        this.survey.sessionId == "OVERALL"
        ? 0
        : this.creditsLeftAfterClaim * -1;
    },
  },
  watch: {
    $route() {
      // react to route changes to 'refresh' on new IDs and clearing it...
      this.sessionId = this.$route.params.id;
    },
    session: {
      handler() {
        if (this.session && this.session.SessionCode) {
          this.getCMESurveyFromServer(this.session.SessionCode);
        }
      },
      deep: true,
    },
  },
};
</script>
